import React from "react";
import "../styles/About.css";
import InterestedForm from "./InterestedForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDiscord,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

function AboutUs() {
  return (
    <div className="aboutUs" id="aboutUsSection">
      <div className="about">
        <div className="aboutHeading">
          What is build<span>OWASP?</span>
        </div>
        {/* <div className="aboutInfo">
          <br />
          buildOWASP is a pioneering inter-college hackathon organized by the
          OWASP NIE Student Chapter at The National Institute of Engineering,
          Mysuru. This groundbreaking event fosters innovation and creative
          problem-solving among students, offering a platform to showcase
          technical prowess and innovative thinking. Participants engage in
          rapid prototyping, creating functional models for immediate feedback
          and iterative improvements. The event aims to address pressing issues
          such as artificial intelligence, cybersecurity threats, data privacy,
          and secure software development. buildOWASP cultivates a culture of
          innovation, equipping the next generation of engineers to tackle
          contemporary challenges. It also provides networking opportunities
          with industry professionals, exposure to real-world problems, and a
          potential launchpad for careers in the tech industry.
        </div> */}
        <br />
        <div className="aboutInfo">
          <ul>
            <li>
              <span>buildOWASP</span> is an <span>inter-college hackathon</span>{" "}
              organized by the <span>OWASP NIE Student Chapter</span> at{" "}
              <span>The National Institute of Engineering, Mysuru.</span>
            </li>
            <li>
              The event fosters <span>innovation</span> and creative{" "}
              <span>problem-solving</span> among students.
            </li>
            <li>
              It offers a platform for students to showcase their{" "}
              <span>technical prowess</span> and{" "}
              <span>innovative thinking</span>.
            </li>
            <li>
              Participants engage in <span>rapid prototyping</span>, creating
              functional models for immediate feedback and iterative
              improvements.
            </li>
            <li>
              The hackathon aims to address pressing issues such as{" "}
              <span>
                artificial intelligence, cybersecurity threats, data privacy,
                and secure software development
              </span>
              .
            </li>
            <li>
              The event provides <span>networking</span> opportunities with
              industry professionals.
            </li>
            <li>
              buildOWASP serves as a potential launchpad for{" "}
              <span>careers</span> in the <span>tech industry</span>.
            </li>
          </ul>
        </div>
      </div>


      <div className="queryFormSection">
      {/* <div className="queryHeading">Have queries? Feel free to inquire.</div> */}
      <br />
      <div className="queryFrom">
        <InterestedForm />
      </div>

      <div className="querySocialHandles">
        <a
          className="queryLogo"
          href="https://discord.com/invite/mh9AcsVZfv"
          target="blank"
        >
          <FontAwesomeIcon icon={faDiscord} style={{ color: "#fff" }} />
        </a>
        <a
          className="queryLogo"
          href="https://instagram.com/owasp_nie"
          target="blank"
        >
          <FontAwesomeIcon icon={faInstagram} style={{ color: "#fff" }} />
        </a>
        <a
          className="queryLogo"
          href="https://www.linkedin.com/company/owasp-nie-mysuru-student-chapter/"
          target="blank"
        >
          <FontAwesomeIcon icon={faLinkedin} style={{ color: "#fff" }} />
        </a>
      </div>
    </div>
  </div>
  );
}

export default AboutUs;
