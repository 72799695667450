/* eslint-disable */
import React, { useEffect, useState } from "react";
import Faq from "react-faq-component";
import "../styles/FaqSection.css";

const data = {
  title: "FAQ",
  rows: [
    {
      title: "Can I participate remotely?",
      content:
        "No. buildOWASP is a completely offline hackathon where you need to be present physically to participate. We will not be providing any remote participation facilities.",
    },
    {
      title: "How much does it cost to participate?",
      content:
        "buildOWASP is completely free. You don't have to pay anything to participate.",
    },
    {
      title: "What are the prerequisites to participate in this hackathon?",
      content:
        "No prerequisites are required to participate in this hackathon. This event is open to participants of all skill levels.",
    },
    {
      title: "What is the venue for buildOWASP?",
      content:
        "Diamond Jubilee Block, NIE South Campus, Mysuru, Karnataka, India.",
    },
    {
      title: "I am a beginner, can I participate?",
      content:
        "As long as you are an enthusiast for development, absolutely. We will have mentors and resources available, along with some workshops targeted for beginners. Come learn and experience your first hackathon at buildOWASP.",
    },
    {
      title:
        "What is the maximum team size? Can I participate as a Solo Developer?",
      content:
        "Teams are of a fixed size: 6 Members. You cannot participate as a Solo Developer.",
    },
    {
      title: "When will buildOWASP start and end?",
      content:
        "buildOWASP starts from 14th June 2024 and ends on 16th June 2024.",
    },
    {
      title:
        "Is the food and accommodation provided free of charge or are there any associated costs?",
      content:
        "Rest assured, your essentials such as meals, water, and coffee are provided by us at no extra cost. Should you crave additional snacks, they will be available for purchase. For sleeping arrangements, we offer a typical hackathon setup with mattresses laid out in a shared space. Additionally, we have secured accommodations at a nearby youth hostel, with transportation to and from the venue offered twice daily. Participants have the option to sleep at the hostel or at the hackathon venue during the night.",
    },
    {
      title: "What benefits do I get for participating?",
      content:
        "We'll have swags, prizes and many more cool rewards to complement buildOWASP. Plus, you'll be mentored by industry experts and gain actual work experience on how projects are developed in a matter of few hours.",
    },
    {
      title: "Where can I reach out for support?",
      content: `You can reach out to <a href="mailto:owaspnie@nie.ac.in">owaspnie@nie.ac.in</a> or contact us on our social media handles.`,
    },
  ],
};

const styles = {
  bgColor: "#1c1917",
  titleTextSize: "3rem",
  titleTextColor: "white",
  rowTitleColor: "white",
  rowContentColor: "#ffa974",
  arrowColor: "#ffa974",
};

const config = {
  animate: true,
  tabFocus: true,
  expandIcon: "+",
  collapseIcon: "-",
};

const FaqSection = () => {
  return (
    <div id="faq" className="faq-wrapper">
      <Faq data={data} styles={styles} config={config} />
    </div>
  );
};

export default FaqSection;
