import React, { useState, useEffect, useCallback, useMemo } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import "../styles/TimeSection.css";
import owaspLogo from "../pics/owaspNieSquare.svg";
import locationGif from "../pics/Location.gif";
import calendarGif from "../pics/Calendar.gif";
import moneyGif from "../pics/Money.gif";

function TimeSection() {
  const targetDate = useMemo(() => new Date("2024-06-13T23:59:00"), []);

  const calculateTimeRemaining = useCallback(() => {
    const now = new Date();
    const timeDifference = targetDate - now;
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
    return { days, hours, minutes, seconds };
  }, [targetDate]);
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining);
  // console.log(timeRemaining)

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);
    return () => clearInterval(timer);
  }, [calculateTimeRemaining]);

  function Countdown(props) {
    return (
      <CountdownCircleTimer
        isPlaying
        strokeWidth="6"
        duration={props.duration}
        size="130"
        initialRemainingTime={props.initialRemainingTime}
        colors="rgb(240, 178, 21)"
        trailColor="#1f2937"
        onComplete={() => {
          return { shouldRepeat: true, delay: 0 };
        }}
      >
        {({ remainingTime }) => (
          <div className="countdownData">
            <div className="countdownTime">{props.remainingTime}</div>
            <div className="countdownName">{props.name}</div>
          </div>
        )}
      </CountdownCircleTimer>
    );
  }

  return (
    <div className="timeSection" id="timeSection">
      <div className="countDownSection">
        <div className="clockHeading">COUNTDOWN TO LAUNCH</div>
        <div className="clock">
          <div className="countdown1">
            <div className="countdown">
              <Countdown
                duration={135 * 24 * 60 * 60}
                initialRemainingTime={timeRemaining.days * 24 * 60 * 60}
                remainingTime={timeRemaining.days}
                name="Days"
              />
            </div>
            <div className="countdown">
              <Countdown
                duration={24 * 60 * 60}
                initialRemainingTime={timeRemaining.hours * 60 * 60}
                remainingTime={timeRemaining.hours}
                name="Hours"
              />
            </div>
          </div>
          <div className="countdown2">
            <div className="countdown">
              <Countdown
                duration={60 * 60}
                initialRemainingTime={timeRemaining.minutes * 60}
                remainingTime={timeRemaining.minutes}
                name="Minutes"
              />
            </div>
            <div className="countdown">
              <Countdown
                duration={60}
                initialRemainingTime={timeRemaining.seconds}
                remainingTime={timeRemaining.seconds}
                name="Seconds"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="venueSection">
        <div className="venueLogo">
          <img src={owaspLogo} alt="" className="venueLogoImg" />
        </div>
        <div className="venueDetails">
          <div className="venueAddress">
            <div className="venueIcon">
              <img src={locationGif} alt="" />
            </div>
            <div className="venueText">Diamond Jubilee Block, NIE South </div>
          </div>
          <div className="venueDate">
            <div className="venueIcon">
              <img src={calendarGif} alt="" />
            </div>
            <div className="venueText">14th, 15th, 16th June 2024 </div>
          </div>
          <div className="venuePrize">
            <div className="venueIcon">
              <img src={moneyGif} alt="" />
            </div>
            <div className="venueText">
              ₹<h3> 1,00,000+ </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TimeSection;
