import React from "react";
import "../styles/HeroSection.css";
import backimg from "../pics/main_bg_circle.svg";
import fallinghoney from "../pics/falling_honey-cropped.svg";
import landingpageimg from "../pics/landingPageImg.svg";
// import { motion } from "framer-motion";
import Typewriter from "typewriter-effect";

function HeroSection() {
  // const animatedText = "Let's Create, Learn & Grow.".split(" ");

  return (
    <div className="heroSection">
      <div className="backgroundContainer">
        <div className="mainBackground">
          <img src={backimg} alt="" className="mainBackgroundImg" />
        </div>
      </div>
      <div className="mainWrapper">
        <div className="fallingHoney">
          <img src={fallinghoney} alt="" className="fallingHoneyImg" />
        </div>
        <div className="mainContainer">
          <div className="textContainer">
            <div className="buildOwasp">
              <span className="build">build</span>
              <span className="OW">OW</span>
              <span className="ASP">ASP</span>
            </div>
            <div className="buildOwaspSlogan">
              The Coolest <span>Hackathon</span> Ever.
            </div>
            <div className="animationText">
              {/* <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                {animatedText.map((el, i) => (
                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                      duration: 3,
                      delay: i / 4,
                    }}
                    key={i}
                  >
                    {el}{" "}
                  </motion.span>
                ))}
              </motion.div> */}
              <Typewriter
                onInit={(typewriter) => {
                  typewriter
                    .typeString("Let's Create, Learn & Grow.")
                    .pauseFor(1000)
                    .start();
                }}
              />
            </div>
            <div className="ctaBtn">
              <a href="#aboutUsSection">
                <button>Interested?</button>
              </a>
            </div>
          </div>
          <div className="imageContainer">
            <img src={landingpageimg} alt="" className="imageContainerImg" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
