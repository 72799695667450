import React, { useState } from 'react';
import build from "../pics/build-cropped.svg"
import "../styles/Header.css"
function Header() {

  const [showMenu, setshowMenu] = useState(false)

  const tooglemenu = () => {
    setshowMenu(!showMenu)
    console.log(showMenu)
  }

  return (
    <>
      <div className='header'>
        <div className="headerLogo">
          <img src={build} alt="" className='headerLogoImg'/>
        </div>
        <div className="tooglelogo" onClick={tooglemenu}>
          <div className={`bar ${showMenu ? 'change' : ''}`} ></div>
          <div className={`bar ${showMenu ? 'change' : ''}`} ></div>
          <div className={`bar ${showMenu ? 'change' : ''}`} ></div>
        </div>
        <div className={`headerTopics ${showMenu ? 'change' : ''}`}>
          <ul className={`topicsList ${showMenu ? 'change' : ''}`}>
          <h1 className='categories'> <img src={build} alt="" className='categoriesImg'/></h1>
            <a href="#timeSection">
              <li className="topicItems" onClick={() => {setshowMenu(false)}}>EventHub</li>
            </a>
            <a href="#aboutUsSection">
              <li className="topicItems" onClick={() => {setshowMenu(false)}}> About Us</li>
            </a>
            <a href="#faq">
              <li className="topicItems" onClick={() => {setshowMenu(false)}}> FAQ</li>
            </a>
          </ul>
        </div>
      </div>
    </>
  );

}

export default Header;

