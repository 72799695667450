import axios from "axios";
import React, { useState } from "react";
import Modal from "react-modal";
import "../styles/InterestedForm.css";
import tick from "../pics/tick.gif";
Modal.setAppElement("#root");

const InterestedForm = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [sponsorName, setSponsorName] = useState("");
  const [sponsorEmail, setSponsorEmail] = useState("");
  const [sponsorMessage, setSponsorMessage] = useState("");
  const handleSubmitStudent = async (event) => {
    event.preventDefault();

    const formData = {
      name,
      email,
      message,
    };
    try {
      const response = await axios.post(
        "https://build-owasp-backend.vercel.app/api/submit-form-student",
        formData
      );
      console.log(response.data);
    } catch (error) {
      console.error("Error submitting form:", error);
    }

    setName("");
    setEmail("");
    setMessage("");
    setModalIsOpen(true);
  };
  const handleSubmitSponser = async (event) => {
    event.preventDefault();
    const formData = {
      sponsorName,
      sponsorEmail,
      sponsorMessage,
    };
    try {
      const response = await axios.post(
        "https://build-owasp-backend.vercel.app/api/submit-form-sponsers",
        formData
      );
      console.log(response.data);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setName("");
    setEmail("");
    setMessage("");
    setModalIsOpen(true);
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#fbca1f",
      borderRadius: "4px",
      padding: "20px",
      width: "50%",
      maxWidth: "300px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };
  return (
    <div className="wrapper" id="interestedForm">
      <div className="card-switch">
        <label className="switch">
          <input type="checkbox" className="toggle" />
          <span className="slider"></span>
          <span className="card-side"></span>
          <div className="flip-card__inner">
            <div className="flip-card__front">
              <div className="title">Student Interest Form</div>
              <form
                className="flip-card__form"
                action=""
                onSubmit={handleSubmitStudent}
              >
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="flip-card__input"
                  placeholder="Name"
                  type="text"
                />
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="flip-card__input"
                  placeholder="Email"
                  type="email"
                />
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className="flip-card__input"
                  placeholder="Message"
                  rows="5"
                />
                <button className="flip-card__btn">Send</button>
              </form>
            </div>
            <div className="flip-card__back">
              <div className="title">Sponsor Interest Form</div>
              <form
                className="flip-card__form"
                action=""
                onSubmit={handleSubmitSponser}
              >
                <input
                  value={sponsorName}
                  onChange={(e) => setSponsorName(e.target.value)}
                  className="flip-card__input"
                  placeholder="Name"
                  type="text"
                />
                <input
                  value={sponsorEmail}
                  onChange={(e) => setSponsorEmail(e.target.value)}
                  className="flip-card__input"
                  placeholder="Email"
                  type="email"
                />
                <textarea
                  value={sponsorMessage}
                  onChange={(e) => setSponsorMessage(e.target.value)}
                  className="flip-card__input"
                  placeholder="Message"
                  rows="5"
                />
                <button className="flip-card__btn">Send</button>
              </form>
            </div>
          </div>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            style={customStyles}
          >
            <img src={tick} alt="tick animation" className="modalGif" />
            <h2 className="modalMessage">
              We've got your message! Our team will get back to you within 48
              hours.
            </h2>
            <button className="modalCta" onClick={() => setModalIsOpen(false)}>
              Got It!
            </button>
          </Modal>
        </label>
      </div>
    </div>
  );
};

export default InterestedForm;
