import "../styles/Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDiscord,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import owaspNieHorizontal from "../pics/OWASP_Logo_Horizontal.png";

function Footer() {
  return (
    <footer id="footer" className="footer">
      <div className="footer-img">
        <img src={owaspNieHorizontal} alt="owaspnie-logo" />
        <h2>
          <a href="mailto:owaspnie@nie.ac.in" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faEnvelope} style={{ color: "#fff" }} />{" "}
            owaspnie@nie.ac.in
          </a>
        </h2>
      </div>
      <div className="vertical-line"></div>

      <div className="social-icons">
        <ul className="socialicons">
          <li>
            <a
              className="queryLogo"
              href="https://www.linkedin.com/company/owasp-nie-mysuru-student-chapter/"
              target="blank"
            >
              <FontAwesomeIcon icon={faLinkedin} style={{ color: "#fff" }} />
            </a>
          </li>
          <li>
            <a
              className="queryLogo"
              href="https://discord.com/invite/mh9AcsVZfv"
              target="blank"
            >
              <FontAwesomeIcon icon={faDiscord} style={{ color: "#fff" }} />
            </a>
          </li>
          <li>
            <a
              className="queryLogo"
              href="https://www.instagram.com/owasp_nie/"
              target="blank"
            >
              <FontAwesomeIcon icon={faInstagram} style={{ color: "#fff" }} />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
