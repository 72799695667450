import "./styles/App.css";

import HeroSection from "./components/HeroSection";
import TimeSection from "./components/TimeSection";
import AboutUs from "./components/AboutUs";
import FaqSection from "./components/FaqSection";
import Footer from "./components/Footer";
import Header from "./components/Header";

function App() {
  return (
    <div className="App">
      <Header/>
      <HeroSection />
      <TimeSection />
      <AboutUs />
      <FaqSection />
      <Footer />
    </div>
  );
}

export default App;
